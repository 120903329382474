.error-main {
    background-color: #fff;
    /* box-shadow: 0px 10px 10px -10px #5D6572; */
}

.error-main h1 {
    font-weight: bold;
    color: #444444;
    font-size: 100px;
    text-shadow: 2px 4px 5px #6E6E6E;
}

.error-main h6 {
    color: #42494F;
}

.error-main p {
    color: #9897A0;
    font-size: 14px;
}