.parallax {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: url('../../images/animation/background.png') no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

#text {
    position: absolute;
    height: 40vh;
    font-size: 5em;
    color: #1b283a;
    text-shadow: 2px 4px 5px #f9f9f9;
}



.parallax img {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 8.5em;
    width: 100%;
    pointer-events: none;
}

.parallax img#gate-left {
    transform: translateX(-282px);

}

.parallax img#gate-right {
    transform: translateX(305px);
}