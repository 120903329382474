:root{
  --ts-dark: #121212;
  --ts-light: #ffffff;
  --ts-primary: #002a9f;
  --ts-padding: 20px;
  --ts-margin:20px;
  --ts-border-radius: 20px;
  --ts-text-body-size: 20px;
  --ts-text-heading-size: 32px;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
