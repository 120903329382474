.typewriter {
    font-size: var(--ts-text-body-size);
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    /* overflow: hidden; */
    /* border-right: .15em solid var(--ts-light); */
    /* white-space: nowrap; */
    margin: 0 auto;
    letter-spacing: .1em;
    text-align: center;
    /* animation: typewriter 4s steps(28) 1s 1 normal both,
        blinkTextCursor 500ms steps(28) infinite normal;  */
}

/* @media only screen and (max-width:992px){
    .typewriter p{
        font-size: cal(var(--ts-text-body-size)*.8);
    }    
}

@keyframes typewriter {
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
}

@keyframes blinkTextCursor {
    from {
        border-right-color:var(--ts-light);
    }

    to {
        border-right-color: transparent;
    }
} */