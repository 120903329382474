.about-section{
  padding-top: calc(var(--ts-padding)*2);
  padding-bottom: calc(var(--ts-padding)*2);
  background-color: var(--ts-dark);
  position: relative;
}

.about-img {
  width: 60%;
}


.about .img-box {
  padding-top:calc(var(--ts-padding));
  padding-bottom: calc(var(--ts-padding));
  filter: drop-shadow(0px 0px 70px );
  color: #0088ff;
  transition-duration: 500ms;
}

.about .img-box:hover{
  filter: drop-shadow(0px 0px 40px );
  /* color: #00ccff; */
  transition-duration: 500ms;
}


.about h2 {
  font-size: calc(var(--ts-text-heading-size)*1.5);
  margin-bottom: calc(var(--ts-margin)*1.5);
  margin-top: calc(var(--ts-margin)*1.5);
  color: var(--ts-light);
}

.about p {
  font-size: var(--ts-text-body-size);
  color: var(--ts-light);
}

.about#right h2 {
  text-align: right;
}

.about#right p {
  text-align: right;
}

.about#left h2 {
  text-align: left;
}

.about#left p {
  text-align: left;
}

@media only screen and (max-width: 992px) {
  
  p, h2{
    text-align: center !important;
    padding-left: var(--ts-padding);
    padding-right: var(--ts-padding);
  }

  .flex{
    display: flex;
    flex-direction: row;
  }
  
  .img-box{
    order: 1;
  }
  
  .text-box{
    order: 2;
  }
  
  
}

