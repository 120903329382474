.portfolio{
  margin: calc(var(--ts-padding)*0.5);
}

.portfolio-card {
  padding: var(--ts-padding);
}

.portfolio-body {
  border-radius: var(--ts-border-radius);
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.3);
  padding: var(--ts-padding);
  background-color: #222222;
}

.portfolio-btn {
  border: none;
  border-radius: var(--ts-border-radius);
  justify-content: center;
  width: 40%;
  font-weight: bold;
  margin: calc(var(--ts-padding)*0.3);
}

.portfolio h2 {
  padding-top: calc(var(--ts-padding)*0.5);
  font-size: calc(var(--ts-text-heading-size)*.8);
  text-align: center;
  color: var(--ts-light);
}

.portfolio p {
  color: var(--ts-light);
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.portfolio img {
  padding: calc(var(--ts-padding));

}