.Contact {
    height: 50vh;
    content: "Contact Us";
    background-image: linear-gradient(to right, rgba(245, 246, 252, 0), rgb(0, 7, 86)), url('../../../images/ui/banner.webp');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    text-align: left;
    color: #ffff;
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: center;
    padding-right: 5rem;
  }


.Contact a{
  cursor: pointer;
  text-decoration: none;
  color: #ffff;
}

.Contact h2 {
  font-size: 3em;
}

.Contact p {
  font-size: 1.2em;
}

@media only screen and (max-width: 992px) {
 
  .Contact{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-right: 0rem;
  }
}