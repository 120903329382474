* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  body {
    min-height: 100vh;
    max-width: 100vw;
    overflow-x: hidden;
  }
