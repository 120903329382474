#copyright {
    background-color: #000000;
    color: #fff;
  }
  
footer{
  color: var(--ts-light);
}
  footer section {
    padding-top: 2em;
    background-color: var(--ts-dark);
    color: #fff;
  }
  
  footer a {
    text-decoration: none;
    cursor: pointer;
    
  }
  
  footer a:hover {
    color: snow;
  }

.footer-icons{
  display: flex;
  flex-direction: row;
}

.social-icon{
  padding: calc(var(--ts-padding)*.8);
  font-size: calc(var(--ts-text-heading-size)*.8);
  color: var(--ts-light);
  transition-duration: 200ms;
  transition-timing-function: ease-in;
}

.social-icon:hover{
  color: var(--ts-primary);
  transition-duration: 200ms;
  transition-timing-function: ease-out;
}

@media only screen and (max-width:992px) {
  .footer-icons{
    justify-content: center;
    align-items: center;
  }
}