.services{
    /* padding: var(--ts-padding); */
    padding-left: calc(var(--ts-padding)*0.5);
    padding-right: calc(var(--ts-padding)*0.5);
}
.service-body {
    padding: calc(var(--ts-padding)*0.5);
    
}

.service-box {
    padding: calc(var(--ts-padding)*1);
    border: whitesmoke 1px solid;
    border-radius: var(--ts-border-radius);
    background-color: whitesmoke;
    cursor: pointer;
    transition-duration: 200ms;
}

.service-box:hover {
    background-color: gainsboro;
    transition-duration: 200ms;
}
.service-box h2 {
    text-align: center;
    font-weight: 700;
    padding-top: calc(var(--ts-padding)*0.5);
    padding-bottom: calc(var(--ts-padding)*0.5);
    font-size: calc(var(--ts-text-heading-size)*0.8);
}

.service-box p {
    color: #555;
}

.service-box img {
    width: 80px;
    /* padding-bottom: 1em; */
    margin: var(--ts-margin);
    aspect-ratio: 1;
}

